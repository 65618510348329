import {
  ChangeEvent,
  createElement,
  forwardRef,
  ForwardRefExoticComponent,
  FocusEvent,
  InputHTMLAttributes,
  ReactNode,
  Ref,
  Fragment
} from 'react';
import { RawFontContainer, DefaultFonts } from 'legos/typography';

import { Container } from './text-input.ccm.css';
import {
  ThemedInput,
  ThemedLabel,
  ThemedMessages,
  ThemedRightIcon
} from './themed-text-input-components';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  /** The name of this form field */
  name: string;
  /** Blur event handler */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Change event handler */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  /** Focus event handler */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Controlled value input value */
  value: string;
  /**
   * Node to set as the label for the field. It is automatically put inside of <label>
   * that is correlated with the input for accessibility and auto focusing on click
   */
  label?: ReactNode;
  /**
   * Sets if input should be required. Helpful for accessability since we do not use HTML5 Validation
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-required
   * @default false
   */
  required?: boolean;
  /**
   * Sets if input should be visually valid or not
   * @default true
   */
  valid?: boolean;
  /**
   * Sets if input should be warned about their value
   * @default false
   */
  warn?: boolean;
  /**
   * Sets if validation spacing should be shown or not
   * @default true
   */
  showValidationSpacing?: boolean;
  /**
   * Sets if input should be marked as disabled. `disabled` keeps the same look and feel and is typically
   * only used to temporarily disable editing a field, such as during a form submit.
   *
   * Note: this is different than `readOnly`.
   * @default false
   */
  disabled?: boolean;
  /**
   * Sets if input should be marked as readOnly. `readOnly` makes the input appear with a lock icon
   * to indicate to the user that it is not able to be edited, however they can still tab to it to
   * hear the contents.
   *
   * Note: this is different than `disabled`.
   * @default false
   */
  readOnly?: boolean;
  /**
   * HTML5 autoComplete attribute
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-autocomplete
   * @default "off"
   */
  autoComplete?: string;
  /**
   * Sets the type of input, useful for keyboard type selection
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-type
   * @default "text"
   */
  type?: string;
  /** Set the input mode if a specific keyboard is desired */
  inputMode?:
    | 'none'
    | 'text'
    | 'decimal'
    | 'numeric'
    | 'tel'
    | 'search'
    | 'email'
    | 'url';
  /**
   * Sets if input should be a large input field. `largeInput` makes the input appear without any label
   * and with big font size (40px). user should use it when this field is main action on the page
   *
   * @default false
   */
  largeInput?: boolean;
  /** Ref object */
  ref?: Ref<HTMLInputElement>;
  /**
   * Sets if input should be marked as readOnly. `withoutLockIcon` makes the input appear without a lock icon
   * to indicate to the user that it is not able to be edited, however they can still tab to it to
   * hear the contents.
   * @default false
   */
  withoutLockIcon?: boolean;
  /**
   * Determines if the field should be encrypted instead of blocked on the Quantum Metrics Platform. On QM,
   * all inputs are blocked by default.  QM specifically looks for the exact attribute
   * data-analytics-encrypt-sensitive and its value to determine if the input field should be encrypted.
   * Business chooses which fields should be encrypted.
   */
  analyticsEncryptSensitive?: boolean;
}
const LabelContainer = RawFontContainer.as(Container.label);

export const TextInput: ForwardRefExoticComponent<TextInputProps> = forwardRef(
  (
    {
      label,
      autoComplete = 'off',
      type = 'text',
      required = false,
      inputMode = 'text',
      valid = true,
      warn = false,
      disabled = false,
      readOnly = false,
      withoutLockIcon = false,
      analyticsEncryptSensitive = false,
      largeInput = false,
      showValidationSpacing = true,
      value,
      name,
      onBlur,
      onFocus,
      onChange,
      children,
      ...inputProps
    },
    ref
  ) => (
    <LabelContainer $color="transparent" $fontFace={DefaultFonts}>
      <ThemedInput
        {...inputProps}
        ref={ref}
        $warn={warn}
        $error={!valid}
        $readonly={readOnly}
        $largeInput={largeInput}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        name={name}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        type={type}
        autoComplete={autoComplete}
        required={required}
        inputMode={inputMode}
        data-analytics-encrypt-sensitive={analyticsEncryptSensitive}
        // TODO is this bad for accessability? We never show it, rather only the label
        // We need it to activate :placeholder-shown
        placeholder=" "

        // Should this be responsibility of TextInput or TextField?
        // For example, we have a "valid" prop but that is more of a "viewValid",
        // not a "modelValid".
        // aria-invalid={meta.invalid}
        // aria-describedby={this.uniqueFieldId}
      />
      {!largeInput && <ThemedLabel>{label}</ThemedLabel>}
      {!withoutLockIcon && (
        <Fragment>
          {readOnly ? (
            <ThemedRightIcon
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <g transform="translate(3.600000, 2.400000)" fill="currentColor">
                <path d="M15,8.4 L14.1,8.4 L14.1,5.7 C14.1,2.5575 11.5425,0 8.4,0 C5.2575,0 2.7,2.5575 2.7,5.7 L2.7,8.4 L1.8,8.4 C0.80625,8.4 0,9.20625 0,10.2 L0,17.4 C0,18.39375 0.80625,19.2 1.8,19.2 L15,19.2 C15.99375,19.2 16.8,18.39375 16.8,17.4 L16.8,10.2 C16.8,9.20625 15.99375,8.4 15,8.4 Z M11.1,8.4 L5.7,8.4 L5.7,5.7 C5.7,4.21125 6.91125,3 8.4,3 C9.88875,3 11.1,4.21125 11.1,5.7 L11.1,8.4 Z" />
              </g>
            </ThemedRightIcon>
          ) : (
            <ThemedRightIcon
              aria-hidden="true"
              focusable="false"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"
              />
            </ThemedRightIcon>
          )}
        </Fragment>
      )}
      <ThemedMessages showValidationSpacing={showValidationSpacing}>
        {children}
      </ThemedMessages>
    </LabelContainer>
  )
);
